import { useContext, useEffect, useState, useRef } from "react";
import { Collapse, Dropdown, initTE, Sidenav } from "tw-elements";
import Menus from "../../Components/layout/menus";
import Info from "../../Components/Info";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, message, Upload } from "antd";
import {
  deleteTimeBaseDrill,
  getListHistoricDrill,
  getListTimeBaseDrill,
  getTBD,
  uploadHistoricDrill,
  uploadTimeBaseDrill,
} from "../../services/api/fetchData";
import { useMutation, useQuery } from "react-query";
import { useFilter } from "../../context/Filter/filterState";
import { config } from "../../config/config";
import { AuthContext } from "../../Auth/context/Auth/AuthContext";
import { useHome } from "../../hooks/useHome";
import classes from "./HomeAdmin.module.css";
import Sidebar from "../../Components/layout/sidebar";
import Navbar from "../../Components/layout/navbar";
import Footer from "../../Components/layout/footer";

const {
  customGrid,
  sidebar,
  header,
  content,
  footer,
  mainContainer,
  mainContainerNoFilter,
  light,
  dark,
  textLight,
  textDark,
  contentWrapper,
  contentInfo,
  infoWrapper,
  containerLayout,
  containerSimpleLayout,
  containerSelectFilter,
  formContainer,
  formContent,
  flexCenter,
  formStructLight,
  formStructDark,
  headingLight,
  headingDark,
  textGray,
  subtitle,
  selectLight,
  selectDark,
  containerHistoricDrillLight,
  containerHistoricDrillDark,
  containerTimeBaseDrillLight,
  containerTimeBaseDrillDark,
  containerDocuments,
  containerBtns,
  fileListContainerDark,
  fileListContainerLight,
} = classes;

export function HomeAdmin() {
  const { getDataFilter, dataFilter } = useFilter();
  const { User } = useContext(AuthContext);
  const { Role } = useContext(AuthContext);
  let visualizarSidebar = sessionStorage.getItem("tabs");
  if (Role != "Simple User") {
    visualizarSidebar = true;
  }
  const user_email = User.user_email;
  const {
    queryUserDataInfo,
    refetchUserDataInfo,
    refetchUserDataClients,
    clients,
    setOption,
    proyects,
  } = useHome();
  const mode = "dark";
  //constantes
  const containerTag = useRef(null);
  const generalRef = useRef(null);
  const historicRef = useRef(null);
  const tbdRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState(0);
  const [tabs, viewTabs] = useState(null);

  const [validateFilter, setValidateFilter] = useState(false);
  const [body, setBody] = useState({
    clients: "",
    proyects: "",
  });

  const [fileList1, setFileList1] = useState([]);
  const [fileList2, setFileList2] = useState([]);
  const [uploading1, setUploading1] = useState(false);
  const [uploading2, setUploading2] = useState(false);
  const [uploading3, setUploading3] = useState(false);
  const [enableListHistoricDrill, setenableListHistoricDrill] = useState(false);
  const [enableListTimeBaseDrill, setenableListTimeBaseDrill] = useState(false);

  //Variables dinamicas
  useEffect(() => {
    initTE({ Collapse, Dropdown, Sidenav });
    refetchUserDataInfo();
  }, []);

  useEffect(() => {
    if (queryUserDataInfo) {
      const storeDataFilter = sessionStorage.getItem("dataFilter");
      if (storeDataFilter) {
        const data = JSON.parse(storeDataFilter);
        if (data?.clients && data?.proyects) {
          setOption(data.clients);
          setTimeout(() => {
            refetchUserDataClients();
          }, 300);
          setBody({
            clients: data.clients,
            proyects: data.proyects,
          });
          setValidateFilter(true);
          setenableListHistoricDrill(true);
          setenableListTimeBaseDrill(true);
        } else {
          console.log("Missing properties in dataFilter");
        }
      }
    }
  }, [queryUserDataInfo]);
  useEffect(() => {
    const calculateMaxHeight = () => {
      const generalHeight = generalRef.current
        ? generalRef.current.scrollHeight
        : 0;
      const wellDesignHeight = historicRef.current
        ? historicRef.current.scrollHeight
        : 0;
      const wellProfileHeight = tbdRef.current
        ? tbdRef.current.scrollHeight
        : 0;

      // Obtener el máximo height de los tres componentes
      let calculatedMaxHeight = Math.max(
        generalHeight,
        wellDesignHeight,
        wellProfileHeight
      );

      // Ajustar el maxHeight basado en el tamaño de la ventana del navegador
      const screenHeight = window.innerHeight;
      const maxHeightAdjustment = 300; // Ajusta este valor según sea necesario
      calculatedMaxHeight = Math.min(
        calculatedMaxHeight,
        screenHeight - maxHeightAdjustment
      );

      setMaxHeight(calculatedMaxHeight);
    };

    calculateMaxHeight();
    window.addEventListener("resize", calculateMaxHeight);

    return () => window.removeEventListener("resize", calculateMaxHeight);
  }, []);

  const handleChange = ({ target: { name, value } }) => {
    if (name === "clients") {
      setOption(value);
      setTimeout(() => {
        refetchUserDataClients();
      }, 300);
    }
    setBody({ ...body, [name]: value });
    setenableListHistoricDrill(false);
    setenableListTimeBaseDrill(false);
  };

  const handleSetFilter = () => {
    if (
      (clients.client !== "" || body.clients !== "") &&
      body.proyects !== ""
    ) {
      setValidateFilter(true);
      getDataFilter({
        clients: body.clients.toLowerCase(),
        proyects: body.proyects.toLowerCase(),
      });
      setenableListHistoricDrill(true);
      setenableListTimeBaseDrill(true);
      message.success(
        "Filter obtained succesfuly, please continue with your browsing"
      );
    }
  };

  const uploadHistoric = useMutation({
    mutationFn: uploadHistoricDrill,
    onSuccess: () => {
      setFileList1([]);
      setUploading1(false);
      message.success("upload successfully.");
      refetchHistoricDrill();
    },
  });

  const uploadTBD = useMutation({
    mutationFn: uploadTimeBaseDrill,
    onSuccess: () => {
      setFileList2([]);
      setUploading2(false);
      message.success("upload successfully.");
      refetchTimeBaseDrill();
    },
  });

  const deleteTBD = useMutation({
    mutationFn: deleteTimeBaseDrill,
    onSuccess: () => {
      message.success("deleted successfully.");
      setUploading3(false);
      refetchTimeBaseDrill();
    },
  });

  const handleUploadHistoric = () => {
    const formData = new FormData();
    fileList1.forEach((file) => {
      formData.append("upload", file);
    });
    setUploading1(true);
    uploadHistoric.mutate({
      file: formData,
      client: dataFilter?.clients,
      proyect: dataFilter?.proyects,
    });
  };

  const handleUploadTimeBaseDrill = () => {
    const formData2 = new FormData();
    fileList2.forEach((file) => {
      formData2.append("upload", file);
    });
    setUploading2(true);
    uploadTBD.mutate({
      file: formData2,
      client: dataFilter?.clients,
      proyect: dataFilter?.proyects,
    });
  };

  const handeleDeleteTimeBaseDrill = () => {
    setUploading3(true);
    deleteTBD.mutate({
      client: dataFilter?.clients,
      proyect: dataFilter?.proyects,
    });
  };

  const props1 = {
    onRemove: (file) => {
      const index = fileList1.indexOf(file);
      const newFileList = fileList1.slice();
      newFileList.splice(index, 1);
      setFileList1(newFileList);
    },
    beforeUpload: (file) => {
      setFileList1([...fileList1, file]);
      return false;
    },
    fileList1,
  };

  const props2 = {
    onRemove: (file) => {
      const index = fileList2.indexOf(file);
      const newFileList = fileList2.slice();
      newFileList.splice(index, 1);
      setFileList2(newFileList);
    },
    beforeUpload: (file) => {
      setFileList2([...fileList2, file]);
      return false;
    },
    fileList2,
  };

  var messageHistoric = "";
  var messageTimeBaseDrill = [];

  const {
    isLoading: isLoadinHistoric,
    error: err1,
    data: dataHistoricDrill,
    refetch: refetchHistoricDrill,
  } = useQuery({
    queryKey: ["historic"],
    queryFn: () =>
      getListHistoricDrill(dataFilter?.clients, dataFilter?.proyects),
    enabled: enableListHistoricDrill,
  });
  if (dataHistoricDrill?.data) {
    messageHistoric = dataHistoricDrill.data;
  }

  const {
    isLoading: isLoadingTimeBaseDrill,
    error: err2,
    data: dataTimeBaseDrill,
    refetch: refetchTimeBaseDrill,
  } = useQuery({
    queryKey: ["timebasedrill"],
    queryFn: () =>
      getListTimeBaseDrill(dataFilter?.clients, dataFilter?.proyects),
    enabled: enableListTimeBaseDrill,
  });

  if (dataTimeBaseDrill !== undefined) {
    if (dataTimeBaseDrill.data !== null) {
      messageTimeBaseDrill = dataTimeBaseDrill.data;
    }
  }
  const handleRemove = (file) => {
    const updatedFileList = fileList1.filter((item) => item.uid !== file.uid);
    setFileList1(updatedFileList);
  };

  const renderFileListHist = () => {
    return fileList1.map((file) => {
      const color =
        file.status === "done"
          ? "text-green-600 flex items-end"
          : "text-white flex items-end";
      return (
        <div key={file.uid} className={`${color}`}>
          {file.name}
          <Button
            title="Clear"
            type="text"
            icon={<DeleteOutlined style={{ color: "#f87171" }} />}
            onClick={() => handleRemove(file)}
          />
        </div>
      );
    });
  };

  const handleRemoveTBD = (file) => {
    const updatedFileList = fileList2.filter((item) => item.uid !== file.uid);
    setFileList2(updatedFileList);
  };
  const renderFileListTBD = () => {
    return fileList2.map((file) => {
      const color =
        file.status === "done"
          ? "text-green-6 flex items-end"
          : "text-white flex items-end";
      return (
        <div key={file.uid} className={`${color}`}>
          {file.name}
          <Button
            title="Clear"
            type="text"
            icon={<DeleteOutlined style={{ color: "#f87171" }} />}
            onClick={() => handleRemoveTBD(file)}
          />
        </div>
      );
    });
  };

  return (
    <div
      className={`${customGrid} ${
        validateFilter ? mainContainer : mainContainerNoFilter
      } ${mode === "light" ? light : dark}`}
    >
      <div className={header}>
        <Navbar />
      </div>
      <div className={sidebar}>{visualizarSidebar && <Sidebar></Sidebar>}</div>
      <div className={content}>
        <div className={contentWrapper} ref={containerTag}>
          {validateFilter && (
            <div className={contentInfo}>
              <div className={infoWrapper}>
                <Info />
              </div>
            </div>
          )}
          <>
            <div
              className={`${
                validateFilter ? containerLayout : containerSimpleLayout
              } `}
            >
              <div className={containerSelectFilter}>
                <div className={formContainer}>
                  <div className={formContent}>
                    <div className={flexCenter}>
                      <form
                        ref={generalRef}
                        className={`${
                          mode === "light" ? formStructLight : formStructDark
                        } `}
                      >
                        <div className="flex lg:justify-between">
                          <h1
                            className={`${
                              mode === "light" ? headingLight : headingDark
                            }`}
                          >
                            HISTORIC AND TIME BASE DRILL FILES
                          </h1>
                        </div>
                        <br />
                        <h1
                          className={`${
                            mode == "light" ? textGray : textLight
                          } ${subtitle}`}
                        >
                          Client
                        </h1>
                        <select
                          name="clients"
                          id="clients"
                          value={body.clients}
                          onChange={handleChange}
                          className={`${
                            mode == "light" ? selectLight : selectDark
                          } `}
                          placeholder="Set a client"
                          required
                          title="Set a client"
                        >
                          <option className={textGray} value={-1}>
                            Set a client
                          </option>
                          {clients.map((option) => (
                            <option
                              className={textGray}
                              key={option.idclient}
                              value={option.client}
                            >
                              {option.client}
                            </option>
                          ))}
                        </select>
                        <h1
                          className={`${
                            mode == "light" ? textGray : textLight
                          } ${subtitle}`}
                        >
                          Project
                        </h1>
                        <select
                          name="proyects"
                          id="proyects"
                          value={body.proyects}
                          onChange={handleChange}
                          className={`${
                            mode == "light" ? selectLight : selectDark
                          } `}
                          placeholder="Set a proyect"
                          required
                        >
                          <option className={textGray} value={-1}>
                            Set a proyect
                          </option>
                          {proyects?.map((option) => (
                            <option
                              className={textGray}
                              key={option.idproyect}
                              value={option.proyect}
                            >
                              {option.proyect}
                            </option>
                          ))}
                        </select>
                        <Button
                          className="btn-1 mt-[35px]"
                          onClick={handleSetFilter}
                        >
                          Go
                        </Button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              {validateFilter && (
                <div className={containerSelectFilter}>
                  <div className={formContainer}>
                    <div className={formContent}>
                      <div className={flexCenter}>
                        <div
                          ref={historicRef}
                          style={{
                            position: "relative",
                          }}
                          className={`${
                            mode === "light"
                              ? containerHistoricDrillLight
                              : containerHistoricDrillDark
                          } `}
                        >
                          <div className="flex lg:justify-between">
                            <h1
                              className={`${
                                mode === "light" ? headingLight : headingDark
                              }`}
                            >
                              HISTORIC DRILL
                            </h1>
                          </div>
                          <div>
                            <p
                              className={`${
                                mode == "light" ? textGray : textLight
                              } ${containerDocuments}`}
                            >
                              {messageHistoric}
                            </p>
                          </div>

                          <div className={containerBtns}>
                            <Upload
                              {...props1}
                              showUploadList={false}
                              multiple={false}
                            >
                              <Button
                                className="btn-1"
                                icon={<UploadOutlined />}
                              >
                                Select File
                              </Button>
                            </Upload>
                            <Button
                              type="primary"
                              onClick={handleUploadHistoric}
                              disabled={fileList1.length === 0}
                              loading={uploading1}
                              className="btn-1"
                            >
                              {uploading1 ? "Uploading" : "Start Upload"}
                            </Button>
                          </div>
                          <div
                            className={`${
                              mode == "light"
                                ? fileListContainerLight
                                : fileListContainerDark
                            } `}
                          >
                            {renderFileListHist()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {validateFilter && (
                <div className={containerSelectFilter}>
                  <div className={formContainer}>
                    <div className={formContent}>
                      <div className={flexCenter}>
                        <div
                          ref={tbdRef}
                          style={{
                            position: "relative",
                          }}
                          className={`${
                            mode === "light"
                              ? containerTimeBaseDrillLight
                              : containerTimeBaseDrillDark
                          } `}
                        >
                          <div className="flex lg:justify-between">
                            <h1
                              className={`${
                                mode === "light" ? headingLight : headingDark
                              }`}
                            >
                              TIME BASE DRILL
                            </h1>
                          </div>
                          <div>
                            <ul
                              className={`${
                                mode == "light" ? textGray : textLight
                              } ${containerDocuments}`}
                            >
                              {(messageTimeBaseDrill || ["No File"]).map(
                                (elemento, index) => (
                                  <li key={index}>
                                    <label
                                      htmlFor=""
                                      className="text-xl text-pc-purple"
                                    >
                                      -{" "}
                                    </label>
                                    <label
                                      htmlFor=""
                                      className={
                                        mode == "light" ? textGray : textLight
                                      }
                                    >
                                      {elemento}
                                    </label>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                          <div className={containerBtns}>
                            <Upload {...props2} showUploadList={false}>
                              <Button
                                className="btn-1"
                                icon={<UploadOutlined />}
                              >
                                Select File
                              </Button>
                            </Upload>
                            <Button
                              type="primary"
                              onClick={handleUploadTimeBaseDrill}
                              disabled={fileList2.length === 0}
                              loading={uploading2}
                              className="btn-1"
                            >
                              {uploading2 ? "Uploading" : "Start Upload"}
                            </Button>
                          </div>
                          {messageTimeBaseDrill?.length > 0 && (
                            <Button
                              loading={uploading3}
                              className="btn-1 min-w-[150px]"
                              onClick={handeleDeleteTimeBaseDrill}
                              disabled={
                              /*   messageTimeBaseDrill?.length === 0 || */
                               uploading3 && (messageTimeBaseDrill?.length <= 0)
                              }
                            >
                              {uploading3 ? "Uploading" : "Remove"}
                            </Button>
                          )}

                          <div
                            className={`${
                              mode == "light"
                                ? fileListContainerLight
                                : fileListContainerDark
                            } `}
                          >
                            {renderFileListTBD()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        </div>
      </div>
      <div className={footer}>
        <Footer user_info={user_email.user} />
      </div>
    </div>
  );
}
