import { config } from "../../config/config";

const BASE_URL_UPLOAD = config.API.BASE_URL_UPLOAD;
const BASE_URL_PLAN_SCENARIO = config.API.BASE_URL_PLAN_SCENARIO
const TOKEN_FILTER = config.AUTH.TOKEN_FILTER;
const TOKEN_PLAN = config.AUTH.TOKEN_PLAN;

export const getListHistoricDrill = async (dataclient, dataproyect) => {
  const client = dataclient;
  const proyect = dataproyect;
  const response = await fetch(
    `${BASE_URL_UPLOAD}/listhistoricdrill/${client}/${proyect}`,
    {
      method: "GET",
      headers: {
        "x-api-key": TOKEN_FILTER,
      },
    }
  );
  return response.json();
};


export const getListTimeBaseDrill = async (dataclient, dataproyect) => {
  if (!dataclient || !dataproyect) {
    throw new Error("Both dataclient and dataproyect are required");
  }

  const client = dataclient;
  const proyect = dataproyect;

  try {
    const response = await fetch(
      `${BASE_URL_UPLOAD}/listtimebaseddrill/${client}/${proyect}`,
      {
        method: "GET",
        headers: {
          "x-api-key": TOKEN_FILTER,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`Error ${response.status}: ${response.statusText}`);
    }
    return response.json();
  } catch (error) {
    // console.log("error:", error.message);
    return { error: error.message };
  }
};

export const uploadHistoricDrill = async (dataHistoric) => {
  const client = dataHistoric.client;
  const proyect = dataHistoric.proyect;
  const response = await fetch(
    `${BASE_URL_UPLOAD}/historicdrill/${client}/${proyect}`,
    {
      method: "POST",
      headers: {
        "x-api-key": TOKEN_FILTER,
      },
      body: dataHistoric.file,
    }
  );
  return response.json();
};

export const uploadTimeBaseDrill = async (timebasedrill) => {
  const client = timebasedrill.client;
  const proyect = timebasedrill.proyect;
  const response = await fetch(
    `${BASE_URL_UPLOAD}/timebaseddrill/${client}/${proyect}`,
    {
      method: "POST",
      headers: {
        "x-api-key": TOKEN_FILTER,
      },
      body: timebasedrill.file,
    }
  );
  return response.json();
};

export const deleteTimeBaseDrill = async (timebasedrill) => {
  const client = timebasedrill.client;
  const proyect = timebasedrill.proyect;
  const response = await fetch(
    `${BASE_URL_UPLOAD}/timebaseddrill/${client}/${proyect}`,
    {
      method: "DELETE",
      headers: {
        "x-api-key": TOKEN_FILTER,
      },
    }
  );
  return response.json();
};
//APIS EN PROCESO DE IMPLEMENTACION
export const getTBD = async (timebasedrill) => {
  const client = timebasedrill.client;
  const proyect = timebasedrill.proyect;
  const response = await fetch(`${BASE_URL_UPLOAD}/listtimebaseddrill/${client}/${proyect}`, {
    method: "GET",
    headers: {
      "x-api-key": TOKEN_FILTER,
    },
  });
  return response.json();
};

export const getHistoricDrill = async (timebasedrill) => {
  const client = timebasedrill.client;
  const proyect = timebasedrill.proyect;
  const response = await fetch(`${BASE_URL_UPLOAD}/historicdrill/${client}/${proyect}`, {
    method: "GET",
    headers: {
      "x-api-key": TOKEN_FILTER,
    },
  });
  return response.json();
};
export const uploadplan = async (plan) => {
  try {
    const client = plan?.filter.clients.toLowerCase();
    const proyect = plan?.filter.proyects.toLowerCase();
    const stream = plan?.filter.platforms.toLowerCase();

    const response = await fetch(
      `${BASE_URL_PLAN_SCENARIO}/plan/${client}/${proyect}/${stream}`,
      {
        method: "POST",
        headers: {
          "x-api-key": TOKEN_PLAN,
        },
        body: plan.file,
      }
    );
    return response.json();
  } catch (error) {
    throw new Error("Problem getting users");
  }
};
