export const config = {
  API: {
    PORT_DB: process.env.REACT_APP_PORT_DB,
    BASE_URL_UPLOAD: process.env.REACT_APP_BASE_URL_EXTDATA,
    BASE_URL_PLAN_SCENARIO: process.env.REACT_APP_BASE_URL_SCENARIO,
  },
  AUTH: {
    TOKEN_PLAN: process.env.REACT_APP_TOKEN_SCENARIO,
    TOKEN_FILTER: process.env.REACT_APP_TOKEN_EXTDATA,
    TOKEN_TABLES: process.env.REACT_APP_TOKEN_TABLES,
    TOKEN_RUN_WELL: process.env.REACT_APP_TOKEN_RUN_WELL,
    TOKEN_STREAM: process.env.REACT_APP_TOKEN_STREAM,
    TOKEN_BUILD_UP_RATE: process.env.REACT_APP_TOKEN_BUILD_UP_RATE,
    TOKEN_PARAMETERS: process.env.REACT_APP_TOKEN_PARAMETERS,
  },
};

export const keyIntegration = {
  keyEncrypt: process.env.REACT_APP_KEYENCRYPT,
};
