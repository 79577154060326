import React from 'react';
import { useNavigate } from "react-router-dom";
import { Tooltip } from "antd"; // Asegúrate de tener importada la librería correcta para Tooltip
import Logo from "../../assets/image/Logo.png";
import { ReactComponent as LogoutIcon } from '../../assets/image/logout.svg'; // Asegúrate de tener la ruta correcta del SVG

export default function Navbar() {
    const navigate = useNavigate();

    const logOut = () => {
        sessionStorage.clear(); // Limpia todos los datos de la sesión
        navigate("/"); // Redirige a la página de inicio o login
    };

    return (
        <nav className="flex items-center justify-between w-full bg-white shadow-md ">
            <div className="ml-4">
                <img src={Logo} alt="Enovate Logo" className="w-9"/>
            </div>
            <div className="relative flex items-center gap-x-4">
                <Tooltip title="Log Out">
                    <button
                        onClick={logOut}
                        className="flex items-center p-0 text-neutral-500 hover:text-pc-purple focus:text-pc-purple disabled:text-black/30 dark:text-neutral-200 lg:px-2"
                    >
                        <LogoutIcon />
                    </button>
                </Tooltip>
            </div>
        </nav>
    );
}
